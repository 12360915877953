<template>
    <notification-create />
</template>

<script>

import NotificationCreate from './notification/NotificationCreate.vue'

export default {
  components: {
    // BRow,
    NotificationCreate,
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  body {
    background-color: #f8f8f8 !important;
  }
  .active {
    border-color: #fff6e8;
    box-shadow: 0 4px 18px -4px rgb(255 255 255);
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: #fff6e8;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #f8af26;
  }
</style>
