<template>
  <div class="notification-create">
    <form-wizard
      color="#F8AC18"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Enviar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="vertical-steps vue-form-wizard-notification-create vertical-steps-notification-create steps-transparent mb-3"
      @on-complete="submitForm">
      <input v-model="notificationData.creationTime" type="hidden">
      <!-- data notification -->
      <tab-content
        title="Dados da Notificação"
        icon="feather icon-file-text"
        :before-change="validationForm">
        <validation-observer ref="registerForm" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Dados da Notificação</h5>
            </b-col>
            <b-col md="8">
              <b-form-group label="Título" label-for="titulo">
                <validation-provider
                  #default="{ errors }"
                  name="titulo"
                  rules="required">
                  <b-form-input
                    id="titulo"
                    v-model="notificationData.notificationName"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Título" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Tipo da Mensagem" label-for="tipo-mensagem">
                <v-select
                  id="tipo-mensagem"
                  v-model="notificationData.severity"
                  name="tipo-mensagem"
                  label="title"
                  :options="option_severity" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Texto da Mensagem"
                label-for="texto-mensagem">
                <validation-provider
                  #default="{ errors }"
                  name="texto mensagem"
                  rules="required">
                  <b-form-textarea
                    id="texto-mensagem"
                    v-model="notificationData.data.message"
                    type="text"
                    placeholder="Texto da Mensagem" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Imóvel" label-for="imovel">
                <validation-provider
                  #default="{ errors }"
                  name="imovel"
                  rules="required">
                  <b-form-input
                    id="imovel"
                    v-model="notificationData.propertyCode"
                    type="text"
                    placeholder="Imóvel" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- anexos -->
      <tab-content title="Anexos" icon="feather icon-user">
        <b-row
          v-for="(input, index) in inputs"
          :key="index"
          cols="12"
          class="mb-1 mr-1">
          <b-col cols="6">
            <b-form-input
              ref="first-file-name"
              v-model="input.description"
              name="description"
              type="text"
              placeholder="Nome do arquivo" />
          </b-col>
          <b-col cols="5">
            <b-form-file
              ref="first-file-input"
              v-model="input.bytes"
              name="bytes"
              placeholder="Escolha o arquivo"
              :accept="typeFiles"
              label-cols-sm="2"
              label-size="sm">
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="primary">
                  {{ names[0] }}
                </b-badge>
              </template>
            </b-form-file>
          </b-col>
          <b-col cols="1">
            <b-button
              v-if="index > 0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-personnalite-2"
              size="sm"
              variant="danger"
              @click="deleteRow(index)">
              <feather-icon icon="DeleteIcon" size="18" class="mr-50" />
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="addRow">+ Incluir Novo Arquivo</b-button>
            </b-form-group>
          </b-col>
        </b-row>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </tab-content>

      <!-- data send -->
      <tab-content title="Envio" icon="feather icon-file-text">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Dados do Envio</h5>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="data-envio" label="Data de Envio">
                <b-form-input
                  id="date-publish"
                  v-model="notificationSubmissionsData.submissionDate"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Perfis Alvos" label-for="perfis-alvos">
              <v-select
                id="perfis-alvos"
                v-model="notificationSubmissionsData.profiles"
                name="perfis-alvos"
                multiple
                label="title"
                :options="option_profile" />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <b-form-checkbox
              id="send-email"
              v-model="notificationSubmissionsData.sendEmail"
              :checked="renot"
              name="send-email"
              switch
              inline>
              <span>Enviar e-mail</span>
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import Moment from 'moment/moment'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import dataTypeFiles from '../../helper/dataTypeFiles'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BBadge,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      typeFiles: dataTypeFiles.types,
      ToastificationContent,
      Moment,
      locale: 'pt',
      required,
      errors: '',
      Portuguese,
      config: {
        enableTime: false,
        dateFormat: 'd-m-Y',
        locale: Portuguese,
      },
      optionsLocal: {
        birthDate: null,
        userId: localStorage.getItem('id'),
        id: null,
      },
      inputs: [
        {
          description: '',
          bytes: '',
        },
      ],
      notificationData: {
        notificationName: null,
        data: {
          message: null,
        },
        creationTime: Moment(new Date()).format('DD-MM-YYYY hh:mm'),
        propertyCode: null,
        submissionDate: null,
        sendEmail: true,
        severity: 0,
        profiles: [],
      },

      notificationFilesData: {
        binaryObject: {
          bytes: 'string',
          description: 'string',
        },
      },
      renot: true,
      notificationSubmissionsData: {
        submissionDate: null,
        sendEmail: true,
        profiles: null,
      },

      option_profile: [],
      option_severity: [
        {
          title: 'Informativa',
          value: 0,
        },
        {
          title: 'Sucesso',
          value: 1,
        },
        {
          title: 'Atenção',
          value: 2,
        },
        {
          title: 'Erro',
          value: 3,
        },
        {
          title: 'Fatal',
          value: 4,
        },
      ],
      endpointNotification: '/api/services/app/Notification/CreateOrEdit',
      endpointNotificationFiles: '/api/services/app/NotificationFiles/CreateOrEdit',
      endpointNotificationSubmissions: '/api/services/app/NotificationSubmissions/CreateOrEdit',
      warning: 'Arquivo a ser anexado deve ter até 1MB',
    }
  },
  mounted() {
    this.listRoles()
  },
  methods: {
    submitForm() {
      this.register()
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.registerForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async register() {
      try {
        const n = {
          notificationName: this.notificationData.notificationName,
          data: {
            message: this.notificationData.data.message,
          },
          creationTime: this.notificationData.creationTime,
          severity: this.notificationData.severity.value,
          propertyCode: null,
        }
        const responseNotification = await this.$http.post(
          this.endpointNotification,
          n,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        const idNotification = responseNotification.data.result
        /* Cadastra imagem */
        if (this.inputs[0].bytes) {
          this.inputs.forEach(input => {
            this.handleFileSelect(input.bytes, e => {
              const ext = e.target.result.split(',')[0]
              const img = e.target.result.split(',')[1]
              if (idNotification) {
                const data = {
                  notificationId: idNotification,
                  binaryObject: {
                    bytes: img,
                    dataType: ext,
                    description: input.description,
                  },
                }
                setTimeout(() => this.notificationFiles(data), 500)
              }
            })
          })
        }

        /* Envia notificação para os perfis */
        if (idNotification) {
          this.notificationSubmissions(idNotification)
          this.msgSuccess()
          setTimeout(() => {
            this.$router.push({ name: 'notification-list' })
          }, 2700)
        }
      } catch (error) {
        this.msgError()
      }
    },

    async notificationFiles(data) {
      try {
        await this.$http.post(this.endpointNotificationFiles, data, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
      } catch (error) {
        this.errors = error.message
      }
    },

    async notificationSubmissions(data) {
      try {
        const profileTypes = []
        this.notificationSubmissionsData.profiles.forEach(element => {
          profileTypes.push(element)
        })

        const n = {
          submissionDate: this.notificationSubmissionsData.submissionDate,
          sendEmail: this.notificationSubmissionsData.sendEmail,
          notificationId: data,
          profiles: Array.from(profileTypes),
        }
        await this.$http.post(this.endpointNotificationSubmissions, n, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
      } catch (error) {
        this.errors = error.message
      }
    },

    handleFileSelect(evt, callback) {
      this.file = evt
      const reader = new FileReader()
      reader.onload = callback
      reader.addEventListener(
        'load',
        () => {
          this.html = reader.result
        },
        false,
      )
      reader.readAsDataURL(this.file)
    },
    clearFiles() {
      this.$refs['file-input'].reset()
    },
    addRow() {
      this.inputs.push({
        description: '',
        bytes: '',
      })
    },
    deleteRow(index) {
      this.inputs.splice(index, 1)
    },

    async listRoles() {
      const resRoles = await this.$http.post(
        '/api/services/app/Role/GetRoles',
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )

      this.option_profile = resRoles.data.result.items.map(res => ({
        value: res.id,
        title: res.displayName,
      }))
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados gravados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
.notification-create {
  // display: flex;
  max-width: 100%;

  .vue-form-wizard .clearfix:after {
    display: none;
  }

  .vertical-steps.vue-form-wizard .wizard-card-footer {
    display: flex;
    justify-content: space-between;
    float: none;
  }

  .vertical-steps.vue-form-wizard .wizard-tab-content,
  .vertical-steps.vue-form-wizard .wizard-card-footer {
    // width: 100%;
    margin-left: 0;
  }
  li.active a {
    background-color: #fff6e8 !important;
  }
  .wizard-icon-container {
    background-color: rgb(248 172 24 / 0%) !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
    box-shadow: 0 3px 6px 0 rgb(105 108 255 / 0%) !important;
  }
  .vue-form-wizard
    .wizard-navigation
    .wizard-nav
    li
    .wizard-icon-circle.checked {
    background-color: rgb(248 172 24 / 0%) !important;
  }
  .vue-form-wizard
    .wizard-navigation
    .wizard-nav
    li.active
    a
    .wizard-icon-circle
    .wizard-icon-container
    .wizard-icon {
    color: #f8ac18 !important;
    font-size: 1.3rem !important;
  }
  .stepTitle {
    padding-right: 12px !important;
  }
  .active {
    border-color: #fff6e800 !important;
    box-shadow: 0 4px 18px -4px #ffffff00 !important;
  }
  .wizard-icon {
    color: #5e5873 !important;
  }
  div.wizard-navigation li.active a span {
    color: #f8af26 !important;
    font-weight: 500 !important;
  }
  div.wizard-navigation li a span {
    color: #5e5873 !important;
    font-weight: 500 !important;
  }

  .wizard-icon-circle {
    margin-right: 0px !important;
  }
  .vertical-steps.vue-form-wizard .wizard-navigation .wizard-nav {
    padding-top: 0px !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li {
    padding-bottom: 0.2rem !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle {
    border: none;
    background-color: #ededed00 !important;
    border-radius: 6px;
  }
}
//---------- custon ----------//
.vue-form-wizard-notification-create {
  background-color: #f8f8f8 !important;
  .wizard-navigation {
    .wizard-nav {
      padding: 0px 25px 0px 0px !important;
      li {
        padding-bottom: 0rem !important;
        .wizard-icon-circle {
          background-color: #f8f8f8 !important;
        }
        &.active {
          .wizard-icon-container {
            background-color: rgb(255 245 230) !important;
            .wizard-icon {
              font-size: 1.3rem !important;
              color: #ffb200 !important;
            }
          }
        }
      }
    }
  }
}
//---------- custon ----------//
.vertical-steps-notification-create {
  &.vue-form-wizard {
    .wizard-card-footer {
      margin-left: 201px !important;
    }
  }
}
</style>
